<!--
* @Author：sun
* @Date：2022年12月05日 13:47:02
* @Function：功能描述
-->
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>欢迎页</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="all">
      <div class="warps">
        <h2>您好，欢迎进入！</h2>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'helloWorld',
  data() {
    return {}
  },
}
</script> 

<style scoped lang="scss">
.all {
  position: relative;
}
.warps {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  h2 {
    font-size: 60px;
  }
}
</style>
